//Variables to replace
const poolId = 'us-east-1_Zfb8rHLit'
const clientId = '28q22flq6j4d3f62rd4papmij8'
const api = 'https://yvsc0f852h.execute-api.us-east-1.amazonaws.com/Prod'

const Config = {
    UserPoolId: poolId,
    AppClientId: clientId,
    ApiURL: api + '/notes'
}

export default Config